.bubble {
  position: relative;
  border-radius: 20px;
  padding: 24px;
}

.bubble-bottom-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid;
  border-right: 12px solid transparent;
  border-top: 12px solid;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}
