.header {
  color: #fff;
  background-color: #1a2229;
}

.bubble {
  border-radius: 20px;
  padding: 24px;
  position: relative;
}

.bubble-bottom-left:before {
  content: "";
  border-top: 12px solid;
  border-bottom: 20px solid #0000;
  border-left: 24px solid;
  border-right: 12px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -24px;
  left: 32px;
}
/*# sourceMappingURL=index.97282539.css.map */
